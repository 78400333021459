import { Box, IconButton, Stack, Typography } from "@mui/material";
import { UserPlate } from "../../generated/graphql";
import { colors } from "../../styles";
import { Delete } from "@mui/icons-material";
import logo from "../../flag-icon.png";

export const usePlates = () => {
  const getPlates = (userPlates: UserPlate[], markPlateToDelete: (id: number) => (event: React.MouseEvent<HTMLButtonElement>) => void) => {
    return userPlates
      .map((plate) => (
        <Stack
          direction={"row"}
          width={"100%"}
          alignItems="center"
          height={100}
          justifyContent="center"
        >
          <Typography
            color={"black"}
            fontSize={40}
            sx={{
              border: "1px solid black",
              width: "60%",
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              width={"20%"}
              sx={{
                backgroundColor: colors.plateBlue,
                height: "100%",
                textAlign: "center",
              }}
            >
              <img src={logo} alt="Flag logo" width={"70%"} />
            </Box>
            <p style={{ width: "80%", textAlign: "center" }}>{plate.vrm}</p>
            <IconButton
              sx={{ width: "fit-content" }}
              onClick={markPlateToDelete(plate.id)}
            >
              <Delete />
            </IconButton>
          </Typography>
        </Stack>
      ))
      .concat([
        <>
          <Stack
            width={"100%"}
            alignItems="center"
            height={100}
            justifyContent="center"
          >
            <Typography color={colors.orange}>NEW VRM</Typography>
          </Stack>
        </>,
      ]);
  };
  return {
    getPlates
  }
}