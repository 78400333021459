import { useState } from "react";
import { calculateAmount } from "../utils/calculator";
import { isWeekend } from "../utils/date";
import { DateTime } from "luxon";
import { FeeType, Site } from "../../generated/graphql";
import { ZONE } from "../constants";

export const useDuration = () => {
  const [duration, setDuration] = useState<number>(0);
  const [reachedMaxDuration, setReachedMaxDuration] = useState<boolean>(false);
  const [timeSteps, setTimeSteps] = useState<number>(0);

  const increaseDuration = (selectedSite: Site | null, selectedFee: FeeType, specialTariffSelected: number | null) => {
    if (selectedSite) {
      const currentTimeInAuckland = DateTime.now();
      const newDuration = duration + timeSteps;
      if (selectedSite.hasMaxTime || selectedSite.hasMaxTimeWeekend) {
        const maxCost = isWeekend(currentTimeInAuckland)
          ? selectedSite.maxCostWeekend
          : selectedSite.maxCost;
        const maxTimeHours = isWeekend(currentTimeInAuckland)
          ? selectedSite.maxTimeWeekend
          : selectedSite.maxTime;
        const amount = calculateAmount(selectedSite, selectedFee, newDuration, reachedMaxDuration, specialTariffSelected);
        if (amount >= maxCost) {
          setDuration(maxTimeHours * 60);
          setReachedMaxDuration(true);
          return;
        }
      }
      setDuration(newDuration);
    }
  };

  const decreaseDuration = (selectedSite: Site | null) => {
    if (duration - timeSteps < 0) {
      return;
    }
    if (selectedSite) {
      if (reachedMaxDuration) {
        setReachedMaxDuration(false);
        setDuration(selectedSite.minimumTime);
      } else {
        setDuration(duration - timeSteps);
      }
    }
  };

  const changeSelectedFee = (newFee: FeeType, selectedSite: Site | null, specialTariffSelected: number | null) => {
    if (newFee === FeeType.Hourly && selectedSite) {
      setDuration(selectedSite.minimumTime + (selectedSite.hasFreeTime ? selectedSite.freeTime : 0));
    } else if (newFee === FeeType.Monthly) {
      const today = DateTime.now();
      const in4Weeks = today.plus({ weeks: 4 }).endOf('day');
      const difference = in4Weeks.diff(today, ["minutes"]);
      setDuration(Math.round(difference.minutes));
    } else if (newFee === FeeType.Early && selectedSite) {
      const today = DateTime.now();
      const limitTime = DateTime.now().endOf("day").minus({ hours: 5 }).set({ minute: 0, second: 0, millisecond: 0 });
      const difference = limitTime.diff(today, ["minutes"]);
      setDuration(difference.minutes);
    } else if (newFee === FeeType.Overnight && selectedSite) {
      const today = DateTime.now();
      const tomorrow = today.plus({ day: 1 });
      let overnightEnd = selectedSite.overnightEnd;
      // if (isSpecialTariff(selectedSite)) {
      //   overnightEnd = selectedSite.specialTariffEnd
      // }
      const limitTime = tomorrow.set({
        hour: parseInt(overnightEnd.split(":")[0]),
        minute: parseInt(overnightEnd.split(":")[1]),
      });
      const difference = limitTime.diff(today, ["minutes"]);
      setDuration(difference.minutes);
    } else if (
      newFee === FeeType.SpecialTariff &&
      selectedSite &&
      specialTariffSelected
    ) {
      const specialTariff = selectedSite.specialTariffPricing?.find(
        (x) => x.id === specialTariffSelected
      );
      if (specialTariff) {
        const today = DateTime.now().setZone(ZONE);
        if (specialTariff.untilTime) {
          const limitTime = today.set({
            hour: parseInt(specialTariff.untilTime.split(":")[0]),
            minute: parseInt(specialTariff.untilTime.split(":")[1]),
          });
          const difference = limitTime.diff(today, ["minutes"]);
          setDuration(difference.minutes * 60);
        } else if (specialTariff.sessionTime) {
          const difference = today
            .plus({ minutes: specialTariff.sessionTime })
            .diff(today, ["minutes"]);
          setDuration(difference.minutes * 60);
        } else {
          if (specialTariff.untilTime) {
            const limitTime = today.set({
              hour: parseInt(specialTariff.untilTime.split(":")[0]),
              minute: parseInt(specialTariff.untilTime.split(":")[1]),
            });
            const difference = limitTime.diff(today, ["minutes"]);
            setDuration(difference.minutes * 60);
          } else if (specialTariff.sessionTime) {
            const difference = today
              .plus({ minutes: specialTariff.sessionTime })
              .diff(today, ["minutes"]);
            setDuration(difference.minutes * 60);
          } else {
            if (
              specialTariff.end < specialTariff.start &&
              today.hour < parseInt(specialTariff.start.split(":")[0])
            ) {
              const tomorrow = today.plus({ day: 1 });
              const limitTime = tomorrow.set({
                hour: parseInt(specialTariff.end.split(":")[0]),
                minute: parseInt(specialTariff.end.split(":")[1]),
              });
              const difference = limitTime.diff(today, ["minutes"]);
              setDuration(difference.minutes * 60);
            } else {
              const limitTime = today.set({
                hour: parseInt(specialTariff.end.split(":")[0]),
                minute: parseInt(specialTariff.end.split(":")[1]),
              });
              const difference = limitTime.diff(today, ["minutes"]);
              setDuration(difference.minutes * 60);
            }
          }
        }
      }
    }
  };

  return { 
    duration, 
    setDuration, 
    increaseDuration, 
    decreaseDuration, 
    setTimeSteps, 
    changeSelectedFee,
    reachedMaxDuration,
    setReachedMaxDuration
  };
}