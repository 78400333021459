import { DateTime } from "luxon";
import { FeeType, Site } from "../../generated/graphql";
import { isWeekend } from "./date";

export const calculateAmount = (
  selectedSite: Site | null,
  selectedFee: FeeType,
  currentDuration: number,
  reachedMaxDuration: boolean,
  specialTariffSelected: number | null
) => {
  let amount = 0;
  if (selectedSite) {
    if (selectedFee === FeeType.Early) {
      amount = selectedSite.earlyBirdPrice;
    } else if (selectedFee === FeeType.Overnight) {
      amount = selectedSite.overnightPrice;
    } else if (selectedFee === FeeType.Monthly) {
      amount = selectedSite.monthlyFee;
    } else if (selectedFee === FeeType.Hourly) {
      if (reachedMaxDuration) {
        const currentTimeInAuckland = DateTime.now();
        amount = isWeekend(currentTimeInAuckland)
          ? selectedSite.maxCostWeekend
          : selectedSite.maxCost;
      } else {
        const currentTimeInAuckland = DateTime.now();
        const costPerHour = isWeekend(currentTimeInAuckland)
          ? selectedSite.costPerHourWeekend
          : selectedSite.costPerHour;
        const actualDuration = currentDuration - (selectedSite.hasFreeTime ? selectedSite.freeTime : 0);
        amount =
          Math.round(
            ((actualDuration / 60) * (costPerHour ?? 0)) *
            100
          ) / 100;
      }
    } else if (selectedFee === FeeType.SpecialTariff) {
      const pricing = selectedSite.specialTariffPricing?.find(
        (x) => x.id === specialTariffSelected
      );
      if (pricing) {
        amount = pricing.price;
      }
    }
  }
  return amount ? amount + (selectedSite?.transactionFee ?? 0) : 0;
};

export const roundToClosestMultiple = (base: number, num: number): number => {
  if (base <= 0) {
    throw new Error("Base number must be greater than 0");
  }

  const quotient = num / base;
  const roundedQuotient = Math.round(quotient);
  return roundedQuotient * base;
}