import { Button, Popover, Stack, Typography } from "@mui/material"
import React, { useState } from "react";
import { Site, useRemovePlateMutation, useRemoveTokenMutation, UserPlate } from "../../generated/graphql";
import { ClipLoader } from "react-spinners";
import { CardToken } from "../types/card-token";
import { StepType } from "../types/step";

export const useDeleteObject = (
  setError: (message: string) => void,
  setPlates: (plates: UserPlate[]) => void,
  setTokens: (tokens: CardToken[]) => void,
  setStep: (step: StepType) => void,
  setSelectedCard: (card: string) => void,
) => {
  const [cardToDelete, setCardToDelete] = useState<string | null>(null);
  const [plateToDelete, setPlateToDelete] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [showDeleteCard, setShowDeleteCard] = useState<boolean>(false);
  const [showDeletePlate, setShowDeletePlate] = useState<boolean>(false);
  const [removeToken, { loading: removeTokenLoading }] =
      useRemoveTokenMutation();
    const [removePlate, { loading: removePlateLoading }] =
      useRemovePlateMutation();

  const markCardToDelete =
    (token: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setCardToDelete(token);
      setShowDeleteCard(true);
    };

  const markPlateToDelete =
    (id: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setPlateToDelete(id);
      setShowDeletePlate(true);
    };

  const hidePopover = () => {
    setAnchorEl(null);
    setCardToDelete(null);
    setShowDeleteCard(false);
    setShowDeletePlate(false);
  };

  const deletePlate = async (plates: UserPlate[], chooseVrm: (plate: string) => void) => {
    const response = await removePlate({
      variables: {
        plateId: plateToDelete ?? -1,
      },
    });
    const { data } = response;
    const result = data?.removePlate;
    if (result) {
      const newPlates = plates.filter((x) => x.id !== plateToDelete);
      setPlates(newPlates);
      if (newPlates.length === 0) {
        chooseVrm("new");
      }
      setShowDeletePlate(false);
      setPlateToDelete(null);
    } else {
      setError("There was a problem deleting the plate");
    }
  }

  const deleteCard = async (selectedSite: Site | null, tokens: CardToken[]) => {
    const response = await removeToken({
      variables: {
        token: cardToDelete ?? "",
        siteId: (selectedSite?.id ?? -1).toString(),
      },
    });
    const { data } = response;
    const result = data?.removeToken;
    if (result) {
      const newTokens = tokens.filter((x) => x.id !== cardToDelete);
      setTokens(newTokens);
      if (newTokens.length === 0) {
        setStep("newpayment");
      }
      setSelectedCard("");
      setShowDeleteCard(false);
      setShowDeletePlate(false);
      setCardToDelete(null);
      setPlateToDelete(null);
    } else {
      setError("There was a problem deleting the card");
    }
  };

  const DeletePopover: React.FC<{
    selectedSite: Site | null,
    tokens: CardToken[],
    plates: UserPlate[],
    chooseVrm: (plate: string) => void
  }> = ({selectedSite, tokens, plates, chooseVrm}) => (
    <Popover open={showDeleteCard || showDeletePlate} anchorEl={anchorEl} onClose={hidePopover}>
      <Stack spacing={1} m={1}>
        <Typography variant={"body1"}>
          Are you sure you want to delete this {showDeleteCard ? 'card' : 'plate'}?
        </Typography>
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
          <Button
            variant="contained"
            size="small"
            onClick={hidePopover}
            sx={{ fontSize: "1rem", height: "fit-content" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (showDeleteCard) {
                deleteCard(selectedSite, tokens);
              } else {
                deletePlate(plates, chooseVrm)
              }
            }}
            sx={{ fontSize: "1rem", height: "fit-content" }}
          >
            {removeTokenLoading || removePlateLoading ? <ClipLoader color="white" /> : "Delete"}
          </Button>
        </Stack>
      </Stack>
    </Popover>
  )

  return {
    DeletePopover,
    markCardToDelete,
    markPlateToDelete,
  }
}