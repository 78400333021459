import { TextField } from "@mui/material";
import { useState } from "react";

const VrmComponent: React.FC<{
  vrm: string
  disabled: boolean;
  setVrm: (vrm: string) => void;
}> = ({vrm, disabled, setVrm}) => (
  <TextField
    id={'vrm-input'}
    label="Vehicle Registration"
    disabled={disabled}
    value={vrm}
    fullWidth
    onChange={(e) => setVrm(e.target.value)}
  />
)

export const useVrm = () => {
  const [vrm, setVrm] = useState<string>("");

  return { vrm, VrmComponent, setVrm };
}