import { SxProps, Theme } from "@mui/material";
import { colors } from "../../styles";
import { FeeType } from "../../generated/graphql";

const chipsTheme: SxProps<Theme> = {
  height: "100%",
  width: "50%",
  borderColor: colors.orange,
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 15,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

export const isChipSelected = (
  type: FeeType,
  selectedType: FeeType,
  specialTariff?: number | null,
  specialTariffSelected?: number | null
) => {
  if (type === selectedType) {
    if (type !== FeeType.SpecialTariff) {
      return {
        ...chipsTheme,
        backgroundColor: colors.orange,
        color: colors.white,
      };
    } else {
      if (
        type === FeeType.SpecialTariff &&
        specialTariffSelected === specialTariff
      ) {
        return {
          ...chipsTheme,
          backgroundColor: colors.orange,
          color: colors.white,
        };
      } else {
        return chipsTheme;
      }
    }
  } else {
    return chipsTheme;
  }
};