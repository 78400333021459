import { DateTime } from "luxon";
import { ZONE } from "../constants";
import { Site, SpecialTariffPricing } from "../../generated/graphql";

export const getSpecialTariffText = (
  selectedSite: Site | null,
  specialTariffsToRender: SpecialTariffPricing[],
  specialTariffSelected: number | null
) => {
  if (selectedSite && selectedSite.specialTariffPricing) {
    if (specialTariffsToRender.length === 0) {
      return <>No special rates available yet</>;
    }
    const pricing = selectedSite.specialTariffPricing.find(
      (x) => x.id === specialTariffSelected
    );
    if (pricing) {
      if (pricing.untilTime) {
        return <>Until {pricing.untilTime} tomorrow</>
      } else if (pricing.sessionTime) {
        const currentTimeInAuckland =
          DateTime.now().setZone(ZONE);
        const limitTime = currentTimeInAuckland.plus({
          hours: pricing.sessionTime,
        });
        const timeToShow = limitTime.toFormat("t");
        return (
          <>
            {pricing.sessionTime} hour parking session
            <br />
            paid until {timeToShow}
          </>
        );
      } else {
        return (
          <>
            From {pricing.start} until {pricing.end}
            {pricing.end < pricing.start ? " tomorrow" : ""}`
          </>
        );
      }
    }
  }
  return <></>;
};