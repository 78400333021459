import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { Tooltip } from "../components/Tooltip";
import React, { useState } from "react";
import { MuiTelInput } from "mui-tel-input";

const PhoneComponent: React.FC<{
  phone: string
  setPhone: (phone: string) => void;
}> = ({phone, setPhone}) => (
  <MuiTelInput
    onlyCountries={["NZ", "CR"]}
    disableDropdown={!window.location.href.includes("?test=true")}
    label="Phone"
    margin="normal"
    defaultCountry="NZ"
    value={phone}
    inputProps={{
      maxLength: 16,
    }}
    fullWidth
    onChange={(newPhone: string) => setPhone(newPhone)}
  />
)

const EmailComponent: React.FC<{
  email: string
  setEmail: (email: string) => void;
}> = ({email, setEmail}) => (
  <TextField
    label="Email"
    value={email}
    type="email"
    margin="normal"
    fullWidth
    onChange={(e) => setEmail(e.target.value)}
  />
)

export const useReceiveCommunication = () => {
  const [receiveEmails, setReceiveEmails] = useState<boolean>(false);
  const [receiveSms, setReceiveSms] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const handleReceiveEmailsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReceiveEmails(event.target.checked);
  };

  const handleReceiveSmsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReceiveSms(event.target.checked);
  };

  const ReceiveEmailComponent = () => (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent={"space-between"}
    >
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleReceiveEmailsChange}
            checked={receiveEmails}
          />
        }
        label="RECEIVE EMAIL NOTIFICATIONS"
      />
      <Tooltip text="Email will be stored to send email notifications when a ticket is about to expire or invoice notifications after a payment has been made." />
    </Stack>
  )

  const ReceiveSMSComponent: React.FC<{
    checkedCreditCard: boolean;
  }> = ({ checkedCreditCard }) => (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent={"space-between"}
    >
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleReceiveSmsChange}
            checked={receiveSms}
            disabled={checkedCreditCard}
          />
        }
        label="RECEIVE TEXT NOTIFICATIONS"
      />
      <Tooltip text="Phone number is used to send notifications and store any information associated to your account. This information is always going to be validated by a 6 digit code sent to your phone number. When clicking on storing or using credit card information, this option must be enabled and can't be unmarked." />
    </Stack>
  )

  return { email, setEmail, EmailComponent, receiveEmails, setReceiveEmails, receiveSms, setReceiveSms, ReceiveEmailComponent, ReceiveSMSComponent, phone, setPhone, PhoneComponent }
}