import { ExpandMore } from "@mui/icons-material"
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { ClipLoader } from "react-spinners"
import { Site, useGetSitesQuery } from "../../generated/graphql";
import { useState } from "react";
import { colors } from "../../styles";

export const useSiteSelector = (
  qrCode: string | undefined,
  setDuration: (duration: number) => void,
  setTimeSteps: (timeSteps: number) => void,
) => {
  const [location, setLocation] = useState<string | number>(qrCode ?? "");
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const { data: sites, loading: sitesLoading } = useGetSitesQuery();

  const handleChange = (event: SelectChangeEvent<typeof location>) => {
    setLocation(event.target.value);
    const site = sites?.getSites.find((x) => x.qrCode === event.target.value);
    if (site) {
      setSiteInfo(site);
    }
  };

  const setSiteInfo = (site: Site) => {
      setSelectedSite(site);
      setLocation(site.qrCode);
      setDuration(site.minimumTime + (site.hasFreeTime ? site.freeTime : 0));
      setTimeSteps(site.timeStep);
    };

  const SiteSelectorComponent: React.FC<{
    loadedBySpecialTariff: boolean;
    validateRenewalLoading: boolean;
  }> = ({loadedBySpecialTariff, validateRenewalLoading}) => (
    <FormControl fullWidth>
      <InputLabel id="location-label">Location</InputLabel>
      <Select
        labelId="location-label"
        value={location}
        label="Location"
        variant="outlined"
        onChange={handleChange}
        disabled={sitesLoading || loadedBySpecialTariff}
        IconComponent={() => (
          <Box paddingRight={1}>
            {sitesLoading || validateRenewalLoading ? (
              <ClipLoader size={"2rem"} color={colors.orange} />
            ) : (
              <ExpandMore />
            )}
          </Box>
        )}
      >
        <MenuItem value={""}>
          <em>None</em>
        </MenuItem>
        {sites &&
          sites.getSites.map((site) => (
            <MenuItem value={site.qrCode} key={site.qrCode}>
              {site.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
  return {
    selectedSite,
    sitesLoading,
    setSelectedSite,
    SiteSelectorComponent,
    sites,
    setSiteInfo,
    location,
  }
}