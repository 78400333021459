import { IconButton, Stack, Typography } from "@mui/material";
import { CardToken } from "../types/card-token";
import { Delete } from "@mui/icons-material";
import { colors } from "../../styles";
import Cards from "react-credit-cards";

export const useCards = () => {
  const getCards = (
    tokens: CardToken[], 
    markCardToDelete: (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => void,
    selectedCard: string | null
  ) => {
      return tokens
        .map((token) => (
          <Stack alignItems={"center"}>
            <Cards
              key={token.id}
              expiry={token.cardExpiry}
              name={token.cardHolder}
              number={token.cardNumber}
              cvc={"XXXX"}
              focused={selectedCard === token.id ? "number" : undefined}
            />
            <IconButton
              sx={{ width: "fit-content" }}
              onClick={markCardToDelete(token.id)}
            >
              <Delete />
            </IconButton>
          </Stack>
        ))
        .concat([
          <>
            <Stack
              width={"100%"}
              alignItems="center"
              height={100}
              justifyContent="center"
            >
              <Typography color={colors.orange}>NEW PAYMENT METHOD</Typography>
            </Stack>
          </>,
        ]);
    };
  return {
    getCards
  }
}