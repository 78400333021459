import { CarSession, FeeType, Site, useFindSessionBySiteAndVrmMutation, UserPlate } from "../../generated/graphql"
import { Button, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { useVrm } from "../../shared/hooks/useVrm";
import { ClipLoader } from "react-spinners";
import { useRef, useState } from "react";
import { ZONE } from "../../shared/constants";
import { DateTime } from "luxon";
import { calculateAmount, roundToClosestMultiple } from "../../shared/utils/calculator";
import { colors } from "../../styles";
import { useReceiveCommunication } from "../../shared/hooks/useReceiveCommunication";
import { Tooltip } from "../../shared/components/Tooltip";
import { StepType } from "../../shared/types/step";
import { useStepHandler } from "../../shared/hooks/useStepHandler";
import { CardToken } from "../../shared/types/card-token";

interface IProps {
  site: Site
}

export const PostPayment: React.FC<IProps> = ({ site }: IProps) => {
  const { vrm, VrmComponent, setVrm } = useVrm()
  const [loadingPayment, setLoadingPayment] = useState<boolean>()
  const [checkedCreditCard, setCheckedCreditCard] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [tokens, setTokens] = useState<CardToken[]>([]);
  const [plates, setPlates] = useState<UserPlate[]>([]);
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [selectedVrm, setSelectedVrm] = useState<string>("new");
  const [findSessionBySiteAndVrm, { loading: loadingSession }] = useFindSessionBySiteAndVrmMutation()
  const [carSession, setCarSession] = useState<CarSession & { amount: number, duration: number } | null>(null)
  const bottomRef = useRef<null | HTMLButtonElement>(null);
  const { email, setEmail, EmailComponent, phone, setPhone, PhoneComponent, receiveEmails, setReceiveEmails, receiveSms, setReceiveSms, ReceiveEmailComponent, ReceiveSMSComponent } = useReceiveCommunication()
  const { payWithTokenLoading, paymentLinkLoading, validateMfaLoading, requestToken, mfa, setMfa, MFAComponent, TimerComponent, step, setStep, processPayment, hasTokenLoading } = useStepHandler(setError, bottomRef, setTokens, setPlates, setSelectedCard, setSelectedVrm, setVrm)

  const handleSearch = async () => {
    try {
      const result = await findSessionBySiteAndVrm({
        variables: {
          siteId: site.id,
          vrm: vrm
        }
      })
      const { data } = result
      if (data) {
        const { findSessionBySiteAndVrm } = data
        if (!findSessionBySiteAndVrm) {
          setError('No session found')
          return
        }
        setError('')
        const duration =
          roundToClosestMultiple(
            site.timeStep,
            (DateTime.fromSeconds(findSessionBySiteAndVrm.startTime, { zone: ZONE }).diffNow().as('minutes') * -1)
          )
        const amount = calculateAmount(site, FeeType.Hourly, duration, false, null)
        setCarSession({
          ...findSessionBySiteAndVrm,
          amount,
          duration,
        })
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.log('Error', error)
      setError('There was an error searching for the session')
    }
  }

  const handleCreditCardChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedCreditCard(event.target.checked);
    if (event.target.checked) {
      setReceiveSms(true);
    }
  };

  const canBuy = carSession && carSession.duration > 0
  const loadingButton =
    validateMfaLoading ||
    paymentLinkLoading ||
    hasTokenLoading ||
    payWithTokenLoading;

  return (
    <Stack spacing={2} width={'100%'} textAlign={'center'}>
      <VrmComponent vrm={vrm} setVrm={setVrm} disabled={false} />
      <Button variant="contained" color="primary" disabled={vrm === ""}
        onClick={handleSearch}>
          {
            loadingSession ? (
              <ClipLoader color={"white"} />
            ) : (
              'Search'
            )
          }
      </ Button>
      {error && <Typography color={"red"}>{error}</Typography>}
      {loadingSession && <ClipLoader size={20} color={"white"} />}
      {
        carSession && (
          <Stack spacing={2} alignItems={'center'}>
            <Typography
              color={colors.blue}
              fontWeight={"bold"}
              fontSize={"1.5rem"}
            >
              Entered at:
            </Typography>
            <Typography
              color={colors.blue}
              fontSize={"1.5rem"}
            >
              {DateTime.fromSeconds(carSession.startTime, { zone: ZONE }).toFormat('yyyy-MM-dd hh:mm a')}
            </Typography>
            <FormGroup>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCreditCardChange}
                      checked={checkedCreditCard}
                    />
                  }
                  label="STORE / USE EXISTING CREDIT CARDS"
                />
                <Tooltip text="Using our payment provider, your credit card information will be stored to facilitate the payment process in future purchases." />
              </Stack>
              {receiveSms && <PhoneComponent phone={phone} setPhone={setPhone} />}
              {!receiveEmails && step === "paytoken" && <EmailComponent email={email} setEmail={setEmail} />}
            </FormGroup>
            <Button
              fullWidth
              disabled={!canBuy}
              ref={bottomRef}
              sx={{ padding: 2 }}
              variant="contained"
              onClick={() => processPayment(site, {
                vrm,
                name: '',
                email,
                phone,
                duration: carSession.duration,
                receiveEmails,
                receiveSms,
                reachedMaxDuration: false,
                specialTariffSelected: null,
                selectedFee: FeeType.Hourly,
                checkedCreditCard,
                selectedCard,
              })}
            >
              {loadingButton ? (
                <ClipLoader color="white" />
              ) : step === "token" ? (
                "VALIDATE"
              ) : `PAY $${carSession.amount.toFixed(2)}`}
            </Button>
          </Stack>
        )
      }
    </Stack>
  )
}