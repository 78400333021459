import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { colors } from "../../styles";
import {
  DayClass,
  FeeType,
  useValidateRenewalQuery,
} from "../../generated/graphql";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { DateTime } from "luxon";
import "react-credit-cards/es/styles-compiled.css";
import { Tooltip } from "../../shared/components/Tooltip";
import { useReadOTP } from "react-read-otp";
import { CardToken } from "../../shared/types/card-token";
import { shortEnglishHumanizer } from "../../shared/utils/humanizer";
import { isSpecialTariff } from "../../shared/utils/specialTariff";
import { isWeekend } from "../../shared/utils/date";
import { ZONE } from "../../shared/constants";
import { PaymentType } from "../../shared/types/paymentType";
import { PostPayment } from "./PostPayment";
import { useVrm } from "../../shared/hooks/useVrm";
import { calculateAmount } from "../../shared/utils/calculator";
import { useDuration } from "../../shared/hooks/useDuration";
import { useDeleteObject } from "../../shared/hooks/useDeleteObject";
import { useReceiveCommunication } from "../../shared/hooks/useReceiveCommunication";
import { isChipSelected } from "../../shared/utils/chips";
import { useSiteSelector } from "../../shared/hooks/useSiteSelector";
import { usePlates } from "../../shared/hooks/usePlates";
import { useCards } from "../../shared/hooks/useCards";
import { getSpecialTariffText } from "../../shared/utils/specialTariffs";
import { useStepHandler } from "../../shared/hooks/useStepHandler";

interface IParams {
  qrCode?: string;
  sessionCode?: string;
}

interface UserPlate {
  id: number;
  vrm: string
}

export const Home: React.FC = () => {
  const { qrCode, sessionCode } = useParams<IParams>();
  const [checkedCreditCard, setCheckedCreditCard] = useState<boolean>(false);
  const [payEarlyBird, setPayEarlyBird] = useState<boolean>(false);
  const [payOvernight, setPayOvernight] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [tokens, setTokens] = useState<CardToken[]>([]);
  const [plates, setPlates] = useState<UserPlate[]>([]);
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [selectedVrm, setSelectedVrm] = useState<string>("new");
  const [selectedFee, setSelectedFee] = useState<FeeType>(FeeType.Hourly);
  const bottomRef = useRef<null | HTMLButtonElement>(null);
  const [loadedBySpecialTariff, setLoadedBySpecialTariff] =
    useState<boolean>(false);
  const [specialTariffSelected, setSpecialTariffSelected] = useState<
    number | null
  >(null);
  const { vrm, VrmComponent, setVrm } = useVrm()
  const { reachedMaxDuration, setReachedMaxDuration, changeSelectedFee, decreaseDuration, duration, setDuration, increaseDuration, setTimeSteps } = useDuration()
  const { payWithTokenLoading, paymentLinkLoading, validateMfaLoading, requestToken, mfa, setMfa, MFAComponent, TimerComponent, step, setStep, processPayment, hasTokenLoading } = useStepHandler(setError, bottomRef, setTokens, setPlates, setSelectedCard, setSelectedVrm, setVrm)
  const { DeletePopover, markCardToDelete, markPlateToDelete } = useDeleteObject(setError, setPlates, setTokens, setStep, setSelectedCard)
  const { email, setEmail, EmailComponent, phone, setPhone, PhoneComponent, receiveEmails, setReceiveEmails, receiveSms, setReceiveSms, ReceiveEmailComponent, ReceiveSMSComponent } = useReceiveCommunication()
  const { location, selectedSite, sites, sitesLoading, SiteSelectorComponent, setSiteInfo } = useSiteSelector(qrCode, setDuration, setTimeSteps)
  const { getPlates } = usePlates()
  const { getCards } = useCards()

  useReadOTP(setMfa, {
    enabled: step === "token",
  });

  const { data: session, loading: validateRenewalLoading } =
    useValidateRenewalQuery({
      variables: {
        sessionCode: sessionCode ?? "",
      },
    });

  useEffect(() => {
    if (
      session &&
      session.validateRenewal &&
      session.validateRenewal.successful
    ) {
      const { renewalInfo } = session.validateRenewal;
      if (renewalInfo) {
        const { siteId, duration, vrm, email, phone, tokens } = renewalInfo;
        const site = sites?.getSites.find((x) => x.id === siteId);
        if (site) {
          setSiteInfo(site);
        }
        setSelectedFee(FeeType.Hourly);
        setDuration(duration);
        setVrm(vrm);
        if (email) {
          setEmail(email);
          setReceiveEmails(true);
        }
        if (phone) {
          setPhone(phone);
          setReceiveSms(true);
        }
        if (tokens.length > 0) {
          setTokens(tokens);
          setSelectedCard(tokens[0].id);
          setStep("paytoken");
        } else {
          setStep("newpayment");
        }
      }
    }
  }, [session]);

  useEffect(() => {
    if (!selectedSite) {
      const site = sites?.getSites.find((x) => isSpecialTariff(x));
      if (site) {
        setLoadedBySpecialTariff(true);
        setSiteInfo(site);
      }
      if (location) {
        const site = sites?.getSites.find((x) => x.qrCode === location);
        if (site) {
          setSiteInfo(site);
        }
      }
    }
  }, [sites]);

  const handleCreditCardChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedCreditCard(event.target.checked);
    if (event.target.checked) {
      setReceiveSms(true);
    }
  };

  const handleEarlyBirdChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPayEarlyBird(event.target.checked);
  };

  const handleOvernightChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPayOvernight(event.target.checked);
  };

  const durationText = shortEnglishHumanizer(duration * 60 * 1000, {
    units: ["h", "m"],
  });
  const [durationFirst, durationSecond] = durationText.split(",");

  let amount = calculateAmount(selectedSite, selectedFee, duration, reachedMaxDuration, specialTariffSelected);

  const choosePaymentMethod = (method: string) => {
    if (method !== "new") {
      setSelectedCard(method);
      setStep("paytoken");
    } else {
      setSelectedCard("");
      setStep("newpayment");
    }
  };

  const chooseVrm = (plate: string) => {
    if (plate !== "new") {
      setSelectedVrm(plate);
      setVrm(plate);
    } else {
      setSelectedVrm("new");
      setVrm("");
    }
  };

  const loadingButton =
    validateMfaLoading ||
    paymentLinkLoading ||
    hasTokenLoading ||
    payWithTokenLoading;

  const canShowEarlyBird =
    selectedSite &&
    selectedSite.hasEarlyBird &&
    DateTime.now().toFormat("HH:mm") > selectedSite.earlyBirdStart &&
    DateTime.now().toFormat("HH:mm") < selectedSite.earlyBirdEnd;

  const overnightStart = selectedSite ? selectedSite.overnightStart : "";
  const overnightEnd = selectedSite ? selectedSite.overnightEnd : "";
  const canShowOvernight =
    selectedSite &&
    selectedSite.hasOvernight &&
    DateTime.now() >
    DateTime.now().set({
      hour: parseInt(overnightStart.split(":")[0]),
      minute: parseInt(overnightStart.split(":")[1]),
    }) &&
    DateTime.now() <
    DateTime.now()
      .plus({ day: 1 })
      .set({
        hour: parseInt(overnightEnd.split(":")[0]),
        minute: parseInt(overnightEnd.split(":")[1]),
      });

  useEffect(() => {
    setReachedMaxDuration(false);
    if (selectedFee === FeeType.Early) {
      setPayEarlyBird(!!canShowEarlyBird);
      setPayOvernight(false);
    } else if (selectedFee === FeeType.Overnight) {
      setPayOvernight(!!canShowOvernight);
      setPayEarlyBird(false);
    } else {
      setPayEarlyBird(false);
      setPayOvernight(false);
    }
  }, [selectedFee]);

  const specialTariffsToRender =
    selectedSite?.specialTariffPricing?.filter((x) => {
      const timeInAuckland = DateTime.now().setZone(ZONE);
      const startLimit = timeInAuckland.set({
        hour: parseInt(x.start.split(":")[0]),
        minute: parseInt(x.start.split(":")[1]),
        second: 0,
      });
      let endLimit = timeInAuckland.set({
        hour: parseInt(x.end.split(":")[0]),
        minute: parseInt(x.end.split(":")[1]),
        second: 0,
      });
      if (x.end < x.start) {
        endLimit = endLimit.plus({ day: 1 });
      }
      return (
        ((isWeekend(timeInAuckland) && x.dayClass === DayClass.Weekend) ||
          (!isWeekend(timeInAuckland) && x.dayClass === DayClass.Weekday)) &&
        timeInAuckland > startLimit &&
        timeInAuckland < endLimit
      );
    }) ?? [];

  useEffect(() => {
    if (selectedSite) {
      if (selectedSite.hasEarlyBird && canShowEarlyBird) {
        setSelectedFee(FeeType.Early);
        setPayEarlyBird(canShowEarlyBird);
      } else if (isSpecialTariff(selectedSite)) {
        setSelectedFee(FeeType.SpecialTariff);
        if (
          selectedSite.specialTariffPricing &&
          specialTariffsToRender.length > 0
        ) {
          setSpecialTariffSelected(specialTariffsToRender[0].id);
        }
      } else if (selectedSite.hasOvernight && canShowOvernight) {
        setSelectedFee(FeeType.Overnight);
        setPayOvernight(canShowOvernight);
      } else {
        setSelectedFee(FeeType.Hourly);
      }
    }
  }, [selectedSite]);

  const canBuy =
    location &&
    duration > 0 &&
    ((receiveEmails && email) || !receiveEmails) &&
    ((receiveSms && phone) || !receiveSms) &&
    ((checkedCreditCard && phone) || !checkedCreditCard) &&
    ((["initial", "token"].includes(step) && !vrm) ||
      (step !== "initial" && vrm && vrm.trim().length > 0)) && (
      !loadedBySpecialTariff || (loadedBySpecialTariff && specialTariffsToRender.length > 0 && specialTariffSelected)
    );

  return (
    <>
      <SiteSelectorComponent loadedBySpecialTariff={loadedBySpecialTariff} validateRenewalLoading={validateRenewalLoading} />
      {
        location && selectedSite && selectedSite.paymentType === PaymentType.POSTPAYMENT && !sitesLoading && (
          <PostPayment site={selectedSite} />
        )
      }
      {location && selectedSite && selectedSite.paymentType === PaymentType.PREPAYMENT && !sitesLoading && (
        <>
          <Stack
            direction={"row"}
            width="100%"
            height={35}
            alignItems="center"
            spacing={2}
            justifyContent={"space-evenly"}
          >
            {loadedBySpecialTariff &&
              specialTariffsToRender.map((x) => (
                <ButtonBase
                  sx={isChipSelected(FeeType.SpecialTariff, selectedFee, x.id, specialTariffSelected)}
                  onClick={() => {
                    if (
                      selectedFee !== FeeType.SpecialTariff &&
                      specialTariffSelected !== x.id
                    ) {
                      changeSelectedFee(FeeType.SpecialTariff, selectedSite, specialTariffSelected)
                      setSelectedFee(FeeType.SpecialTariff);
                      setSpecialTariffSelected(x.id);
                    }
                  }}
                >
                  {x.name}
                </ButtonBase>
              ))}
            {!loadedBySpecialTariff && (
              <>
                <ButtonBase
                  sx={isChipSelected(FeeType.Hourly, selectedFee)}
                  onClick={() => {
                    changeSelectedFee(FeeType.Hourly, selectedSite, specialTariffSelected)
                    setSelectedFee(FeeType.Hourly);
                  }}
                >
                  Hourly Fee
                </ButtonBase>
                {selectedSite.hasMonthly && (
                  <ButtonBase
                    sx={isChipSelected(FeeType.Monthly, selectedFee)}
                    onClick={() => {
                      changeSelectedFee(FeeType.Monthly, selectedSite, specialTariffSelected)
                      setSelectedFee(FeeType.Monthly)
                    }}
                  >
                    Monthly Fee
                  </ButtonBase>
                )}
                {canShowEarlyBird && (
                  <ButtonBase
                    sx={isChipSelected(FeeType.Early, selectedFee)}
                    onClick={() => {
                      changeSelectedFee(FeeType.Early, selectedSite, specialTariffSelected)
                      setSelectedFee(FeeType.Early)
                    }}
                  >
                    Early Bird Fee
                  </ButtonBase>
                )}
                {canShowOvernight && (
                  <ButtonBase
                    sx={isChipSelected(FeeType.Overnight, selectedFee)}
                    onClick={() => {
                      changeSelectedFee(FeeType.Overnight, selectedSite, specialTariffSelected)
                      setSelectedFee(FeeType.Overnight)
                    }}
                  >
                    Overnight Fee
                  </ButtonBase>
                )}
              </>
            )}
          </Stack>
          {selectedFee === FeeType.Hourly && (
            <>
              <Stack
                direction={"row"}
                width={"100%"}
                justifyContent="space-evenly"
                alignItems={"center"}
              >
                <Button
                  variant="outlined"
                  onClick={() => decreaseDuration(selectedSite)}
                  sx={{ borderRadius: "50%", height: "5rem", width: "5rem" }}
                  disabled={duration <= (selectedSite?.minimumTime ?? 0)}
                >
                  -
                </Button>
                <Stack alignItems={"center"}>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={"2rem"}
                    color={colors.blue}
                  >
                    {durationFirst.trim()}
                  </Typography>
                  {durationSecond && (
                    <Typography color={colors.blue}>
                      {durationSecond.trim()}
                    </Typography>
                  )}
                </Stack>
                <Button
                  variant="outlined"
                  onClick={() => increaseDuration(selectedSite, selectedFee, specialTariffSelected)}
                  disabled={reachedMaxDuration}
                  sx={{ borderRadius: "50%", height: "5rem", width: "5rem" }}
                >
                  +
                </Button>
              </Stack>
              <Typography
                color={colors.blue}
                textAlign={"center"}
              >{`Increase or decrease time in intervals of ${selectedSite?.timeStep} minutes with a minimum of ${selectedSite?.minimumTime} minutes`}</Typography>
              {
                selectedSite.hasFreeTime && (
                  <Typography
                    color={colors.blue}
                    textAlign={"center"}
                  >{`Free time: ${selectedSite.freeTime} minutes`}</Typography>
                )
              }
            </>
          )}
          {selectedFee === FeeType.Monthly && (
            <Stack
              alignItems={"center"}
              height="5rem"
              justifyContent={"center"}
            >
              <Typography
                fontWeight={"bold"}
                fontSize={"2rem"}
                color={colors.blue}
              >
                4 Weeks
              </Typography>
            </Stack>
          )}
          {selectedFee === FeeType.Early && (
            <Stack
              alignItems={"center"}
              height="5rem"
              justifyContent={"center"}
            >
              <Typography
                fontWeight={"bold"}
                fontSize={"2rem"}
                color={colors.blue}
              >{`Until 6pm`}</Typography>
            </Stack>
          )}
          {selectedFee === FeeType.Overnight && (
            <Stack
              alignItems={"center"}
              height="5rem"
              justifyContent={"center"}
            >
              <Typography
                fontWeight={"bold"}
                fontSize={"2rem"}
                color={colors.blue}
                textAlign={"center"}
              >
                {/* {`Until ${isSpecialTariff(selectedSite) ? selectedSite.specialTariffEnd : selectedSite.overnightEnd} tomorrow`} */}
                {`Until ${selectedSite.overnightEnd} tomorrow`}
              </Typography>
            </Stack>
          )}
          {selectedFee === FeeType.SpecialTariff && (
            <Stack
              alignItems={"center"}
              height="5rem"
              justifyContent={"center"}
            >
              <Typography
                fontWeight={"bold"}
                sx={{
                  fontSize: {
                    xs: '1.5rem',
                    sm: '2rem'
                  },
                }
                }
                color={colors.blue}
                textAlign={"center"}
              >
                {getSpecialTariffText(selectedSite, specialTariffsToRender, specialTariffSelected)}
              </Typography>
            </Stack>
          )}
          <Stack alignItems={"center"}>
            {selectedSite.transactionFee > 0 ? (
              <Typography textAlign={"center"} color={colors.blue}>
                Credit card fee of ${selectedSite.transactionFee.toFixed(2)} is
                included in the transaction
              </Typography>
            ) : (
              <Typography textAlign={"center"} color={colors.blue}>
                Credit card fee is included in the transaction
              </Typography>
            )}
            <FormGroup>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCreditCardChange}
                      checked={checkedCreditCard}
                    />
                  }
                  label="STORE / USE EXISTING CREDIT CARDS"
                />
                <Tooltip text="Using our payment provider, your credit card information will be stored to facilitate the payment process in future purchases." />
              </Stack>
              <ReceiveSMSComponent checkedCreditCard={checkedCreditCard} />
              {receiveSms && <PhoneComponent phone={phone} setPhone={setPhone} />}
              <ReceiveEmailComponent />
              {canShowEarlyBird && selectedFee === FeeType.Early && (
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleEarlyBirdChecked}
                        checked={payEarlyBird}
                        disabled={true}
                      />
                    }
                    label="PAY EARLY BIRD"
                  />
                  <Tooltip text="Since the ticket is being paid within the range of early bird tickets, you can opt in to get a reduced fee for a longer period of time" />
                </Stack>
              )}
              {canShowOvernight && selectedFee === FeeType.Overnight && (
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleOvernightChecked}
                        checked={payOvernight}
                        disabled={true}
                      />
                    }
                    label="PAY OVERNIGHT"
                  />
                  <Tooltip text="Since the ticket is being paid within the range of overnight tickets, you can opt in to get a reduced fee for a longer period of time" />
                </Stack>
              )}
              {receiveEmails && <EmailComponent email={email} setEmail={setEmail} />}
            </FormGroup>
          </Stack>
          {requestToken && step === "token" && (
            <>
              <TimerComponent />
              <MFAComponent mfa={mfa} setMfa={setMfa} />
            </>
          )}
          {tokens.length > 0 && (
            <>
              <Typography color={colors.blue}>
                Please select a payment method
              </Typography>
              <Carousel
                sx={{ width: "100%" }}
                autoPlay={false}
                swipe={true}
                fullHeightHover={true}
                navButtonsAlwaysVisible={true}
                strictIndexing={false}
                indicators={false}
                onChange={(now) => {
                  if (now === undefined) return;
                  if (now >= tokens.length) {
                    choosePaymentMethod("new");
                  } else {
                    choosePaymentMethod(tokens[now].id);
                  }
                }}
              >
                {getCards(tokens, markCardToDelete, selectedCard)}
              </Carousel>
            </>
          )}
          {
            <>
              {plates.length > 0 && (
                <>
                  <Typography color={colors.blue}>
                    Please select your registration number
                  </Typography>
                  <Carousel
                    sx={{ width: "100%" }}
                    autoPlay={false}
                    swipe={true}
                    height={100}
                    fullHeightHover={true}
                    navButtonsAlwaysVisible={true}
                    strictIndexing={false}
                    indicators={false}
                    onChange={(now) => {
                      if (now === undefined) return;
                      if (now >= plates.length) {
                        chooseVrm("new");
                      } else {
                        chooseVrm(plates[now].vrm);
                      }
                    }}
                  >
                    {getPlates(plates, markPlateToDelete)}
                  </Carousel>
                </>
              )}
              {(step === "newVrm" ||
                (["paytoken", "newpayment"].includes(step) &&
                  selectedVrm === "new")) && (
                  <VrmComponent vrm={vrm} setVrm={setVrm} disabled={selectedVrm !== "new"} />
                )}
            </>
          }
          {!receiveEmails && step === "paytoken" && <EmailComponent email={email} setEmail={setEmail} />}
          {error && <Typography color={"red"}>{error}</Typography>}
          <Button
            fullWidth
            disabled={!canBuy}
            ref={bottomRef}
            sx={{ padding: 2 }}
            variant="contained"
            onClick={() => processPayment(selectedSite, {
              vrm,
              name,
              email,
              phone,
              duration,
              receiveEmails,
              receiveSms,
              reachedMaxDuration,
              specialTariffSelected,
              selectedFee,
              checkedCreditCard,
              selectedCard,
            })}
          >
            {loadingButton ? (
              <ClipLoader color="white" />
            ) : step === "token" ? (
              "VALIDATE"
            ) : loadedBySpecialTariff && !specialTariffSelected ?
              'SELECT A RATE'
              :
              (
                `PAY $${amount.toFixed(2)}`
              )}
          </Button>
        </>
      )}
      <DeletePopover
        selectedSite={selectedSite}
        tokens={tokens}
        plates={plates}
        chooseVrm={chooseVrm}
      />
    </>
  );
};
